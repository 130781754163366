/*========================================================
  4.  SIDEBAR 
========================================================== */
	

	#blog-sidebar {
		.widget:first-child {
			h3 {
				margin-top: 0;
			}
		}
	}

	/** General Widget */
	.widget {
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 30px;

		&:after {
		  content: '';
		  width: 30px;
		  height: 1px;
		  background: $body-border;
		  position: absolute;
		  left: 0;
		  bottom: 0;
		}

		&:nth-last-child(1) {
			&:after {
			  background: transparent;
			}	
		}

		h3 {
			font-size: 16px;
			color: $body-heading;
		}

		a {
			&:hover {
				color: $link-color-hover;
			}
		}		
	}


	/** Search Widget */
	.widget_search {
		form {
			position: relative;

			input {
				background: #fff;
				width: 80%;
				border-radius: 3px;
				border: 0;
				padding-left: 40px;
			}	
			button {
				position: absolute;
				top: 9px;
				left: 6%;
				background: transparent;
				border: 0;
				box-shadow: none;
				margin: 0;
				padding: 0;
				color: #D8D8D8;
			}		
		}
	}

	/** Link List */
	.widget_list {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;

			a {
				color: $body-link;
				padding: 0 2px;
			}

			li.active {
				a {
					background: $accent-bg;
					color: $accent-text;
				}
			}
		}	
	}

	/** Portfolio Filter */
	.widget_filter {
		ul {
			li {
				a.active  {
					color: $accent-text;
					background: #dbdbdb;
				}
			}
		}	
	}	

	/** Tag Cloud */
	.widget_tag_cloud a {
		display: inline-block;
		font-size: 15px !important;
		line-height: 28px;
		padding: 4px 15px;
		margin-right: 8px;
		margin-bottom: 11px;
		background: $tag-bg url(../../images/button-glass.png) repeat-x 0 0;
		color: $tag-text;
		border-radius: 3px;
		border-bottom: 0px none;
		text-decoration: none;
		transition: all 300ms ease-in-out 0s;

		&:hover {
			background-color: #7bbb5e;
			color: #fff;
		}
	}

	/** Newsletter */
	.widget_newsletter {

		.widget_wrapper {
			background: #3B3C3E;
			padding: 22px 30px 32px 30px;
			margin-bottom: 10px;
			border-radius: 3px;

			h3 {
				font-size: 16px;
				color: #fff;
				margin: 0 0 10px 0;
			}
			p {
				font-size: 15px;
				color: #999999;
				margin-bottom: 10px;
			}
			.button {
				width: 100%;
				text-align: center;
				
				&:hover {
					color: #3C3C3C;
				}

				i {
					margin-right: 5px;
				}
			}			
		}
	}

	/** Address */
	.widget_address {
		.widget-content {
			position: relative;
			background: #e4e4e4;
			padding: 22px 30px 32px 30px;
			margin-bottom: 10px;
			border-radius: 3px;

			&:before {
				position: absolute;
				top: 10px;
				right: 20px;
				left: auto;
				font-family: 'FontAwesome';
				content: "\f08d";
				color: #000;
				font-size: 24px;
				opacity: 0.2;
			}

			h3 {
				font-size: 16px;
				color: #777777;
				font-weight: bold;
				margin: 0 0 10px 0;
			}
			p {
				font-size: 15px;
				color: #999999;
				margin-bottom: 0;
			}
			.button {
				width: 100%;
				text-align: center;
				
				&:hover {
					color: #3C3C3C;
				}

				i {
					margin-right: 5px;
				}
			}		
		}
	}	