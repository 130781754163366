/*========================================================
  6.  BLOG 
========================================================== */

	.blog.single {
		.entry-desc {
			h3	{
				padding: 0 3px;
			}
		}
		.entry-meta {
			margin-top: 10px !important;
		}
	}


	.blog {

		/** General Styling */
		.entry {
			background: $container-bg;
			margin-bottom: 32px;
			box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

			.entry-media {
				margin: 0;
				overflow: hidden;
				position: relative;

				a {
					display: block;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 9999;
						opacity: 0;
						background: rgba(0, 0, 0, 0.3);
						transition: opacity 300ms ease-in-out 0s;
					}

				}
				img {
					transition: transform 300ms ease-in-out 0s;
					width: 100%;
					display: block;
				}
			}
			.entry-desc {
				padding: 20px 35px;

				h3 {
					font-size: 24px;
					margin-bottom: 10px;
					margin-top: 5px;
					font-weight: bold;

					a {
						color: $container-heading;
						transition: background 300ms ease-in-out 0s;
						padding: 3px;

						&:hover {
							color: $accent-text;
							background: #F6F6F6;
						}						
					}


				}
				p {
					color: #96999d;
				}
			}
			.entry-meta {
				margin: 0;
				list-style: none;
				padding: 0 36px 30px 36px;
				@include clearfix;

				li {
					display: inline-block;
					font-size: 15px;
					color: #96999d;
					float: right;

					&:first-child {
						float: left;
					}

					a {
						transition: color 300ms ease-in-out 0s;

						i {
							transition: color 300ms ease-in-out 0s;
							color: $container-icon;
						}

						&:hover {
							color: $container-icon-hover;
							i {
								color: $container-icon-hover;
							}
						}
					}					

				}
				li.date i {
					margin-right: 8px;
					font-size: 17px;
				}				
				li.like {
					margin-left: 15px;

					i {
						font-size: 20px;
						margin-right: 5px;
					}
					a:hover {
						color: #7bbb5e;
						i {
							color: #7bbb5e;
						}
					}


				}
				li.comments {
					i {
						font-size: 18px;
						margin-right: 5px;
					}
				}				
			}
		}

		/** Standard Format */
		.format-standard:hover {

			.entry-media {
				a {

					img {
						transform: scale(1.04);
					}

					&:after { opacity: 1; }

				}
			}

		}

		/** Quote Format */
		.format-quote {

			.entry-media {
				position: relative;
				background: $post-format-bg;
				padding: 35px 60px 38px 60px;

				blockquote {
					padding: 0;
					margin: 0;
					border: 0;

					&:after {
						position: absolute;
						top: 34px;
						left: 30px;
						font-family: 'FontAwesome';
						content: "\f10d";
						color: $post-format-icon;
						font-size: 23px;
					}

					p {
						position: relative;
						color: $post-format-text;
						font-size: 23px;
						line-height: 32px;
						font-style: italic;
						letter-spacing: 0;
						text-transform: initial;
						font-weight: normal;
						padding: 0 2px 0 35px;

						&:before {
							content: '';
							width: 1px;
							height: 100%;
							background: $post-format-border;
							position: absolute;
							left: 10px;
							top: 0;
						}
									
					}
				}
			}
		}

		/** Link Format */
		.format-link {
			.entry-media {
				position: relative;
				background: $post-format-bg;
				padding: 35px 60px 38px 60px;

				p {
					padding: 0;
					margin: 0;
					border: 0;

					&:after {
						position: absolute;
						top: 34px;
						left: 30px;
						font-family: 'FontAwesome';
						content: "\f0c1";
						color: $post-format-icon;
						font-size: 23px;
					}

					a {
						position: relative;
						color: $post-format-text;
						font-size: 23px;
						line-height: 32px;
						font-style: italic;
						letter-spacing: 0;
						text-transform: initial;
						font-weight: normal;
						padding: 0 2px 0 35px;

						&:before {
							content: '';
							width: 1px;
							height: 100%;
							background: $post-format-border;
							position: absolute;
							left: 10px;
							top: 0;
						}
									
					}
				}
			}
		}	

	}


	/** Gallery Format */
	.format-gallery  {

		.slick-arrow {
			position: absolute;
			right: 20px;
			bottom: 20px;
			background: #3b3c3e;
			border: 1px solid #3b3c3e;
			width: 32px;
			height: 32px;
			line-height: 30px;
			text-align: center;
			color: #fff;
			border-radius: 2px;
			transition: all 300ms ease-in-out 0s;

			i.fa {
				font-size: 12px;
			}

			&.prev-arrow {
				right: 57px;
				z-index: 99;
			}
			&:hover {
				background: $alt-accent-bg;
				color: $alt-accent-text;
				border: 1px solid darken($alt-accent-bg, 5%);
			}
		}
	}		