// Center Positioning Mixin 
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}


// Clearfox Mixin 
@mixin clearfix {
    *zoom: 1;

    &:before, &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
}