/*========================================================
  1.  BASE 
========================================================== */

	html {
		font-size: 62.5%;
	}

	body {
		font-family: $secondary-font-stack;
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 1.6;
		color: $body-text;
		background: $body-bg;
	}

	*, *:before, *:after {
	  box-sizing: border-box;
	}

	::selection {
	    color: #000;
	    background: #FBF285;
	}

/** == TYPOGRAPHY == */
/** ================================================== */

	a {
		text-decoration: none;
		color: inherit;
		cursor: pointer;

		&:hover {
			color: $link-color-hover;
		}
	}

	/** Headings */
	h1, h2, h3, h4, h5, h6 {
		font-family: $primary-font-stack;
		font-weight: normal;
		color: $body-heading;
		margin: 1rem 0 1.5rem;
	}

	h1 { font-size: 2.8rem; }
	h2 { font-size: 2.4rem; }
	h3 { font-size: 2rem; }
	h4 { font-size: 1.8rem; }
	h5 { font-size: 1.6rem; }
	h6 { font-size: 1.6rem; }

	/** Paragraphs */
	p { margin:  0 0 2rem 0; color: $body-text; }
	p:last-child { margin-bottom: 0; }

	/** Blockquotes */
	blockquote {
		text-transform: uppercase;
		padding: 25px;
		color: #999999;
		letter-spacing: 0.1em;
		margin-top: 25px;
		border-left: 4px solid #E1E1E1;
	}

	blockquote:first-child { margin-top: 0; }


/** == LISTS == */
/** ================================================== */

	ul, ol, dl {
	  margin-bottom: 20px;
	  margin-left: 0;
	}

	ul {
	  list-style: square inside;
	}
	ol {
	    list-style: decimal inside;
	}

	ul ul,
	ol ol {
	  margin: 0 0 0 20px;
	}

	dt {
	    font-weight: bold;
	}
	dd {
	  margin: 0 0 20px;
	}


/** == BLOCK QUOTES == */
/** ================================================== */


	blockquote {
	  clear: both;
	  margin: 40px 40px 40px 0;
	  padding: 15px 0 15px 40px;
	  border: 0;
	  border-left: solid 1px darken(#e5e5e5, 10%);
	}

	blockquote p {
	  color: #aaa;
	}

	blockquote > p:last-child {
	  margin-bottom: 0;
	}

	blockquote cite,
	blockquote small {
	  display: block;
	  margin-top: 10px;
	}

	blockquote em,
	blockquote i,
	blockquote cite {
	  font-style: normal;
	}

	blockquote strong,
	blockquote b {
	  font-weight: 400;
	}


/** == RULES == */
/** ================================================== */

	hr {
	  clear: both;
	  background-color: darken($container-border, 5%);
	  border: 0;
	  height: 1px;
	  margin: 5rem 0;
	}


/** == PRE == */
/** ================================================== */

	pre {
		position: relative;
		background: rgba(0, 0, 0, 0.05);
		font-family: "Courier 10 Pitch", Courier, monospace;
		font-size: 15px;
		line-height: 1.6;
		margin-bottom: 1.6em;
		padding: 3% 5% 3% 10%;
		overflow: auto;
		max-width: 100%;
		tab-size: 4;
	}

	pre:before {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		font-family: "FontAwesome";
		content: "\f121";
		background: #BBC3CC;
		color: #fff;
		padding: 0 3px;
		font-size: 14px;
		z-index: 5;
	}

	code, kbd, tt, var { font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace }


/** == TABLE == */
/** ================================================== */

	table {
		width: 100%;
		border: dotted 1px rgba(0, 0, 0, 0.1);
		padding: 1% 3%;
		font-size: 16px;
	}

	table td, table th {
		padding: 10px;
		text-align: left;
	}

	table thead { background: rgba(0, 0, 0, 0.05); }

	table thead th { font-weight: bold; }

	table td { border-bottom: solid 1px rgba(0, 0, 0, 0.05); }

	table tr:last-child td { border-bottom: none; }

	table tr:nth-child(even) { background: rgba(0, 0, 0, 0.05); }


/** == MISC == */
/** ================================================== */

	.clear {
		clear: both;
		line-height: 1px;
		height: 1px;
	}