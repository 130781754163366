/*========================================================
  7.  COMMENTS 
========================================================== */
	
	/** Comments List */
	#comments {
		margin-top: 80px;

		h3 {
			font-size: 22px;
			color: $container-heading;
			margin-bottom: 0.5rem;
		}

		> p {
			color: $container-text;
		}

		ol.comments-list {
			padding-left: 0;
			margin-top: 40px;

			li.comment {
				list-style: none;
				margin-top: 30px;
				padding: 28px 35px;
				background: $container-bg;
				border-radius: 2px;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
				@include clearfix;

				.author-avatar {
					float: left;
					width: 90px;
					padding-left: 0;
					margin-right: 25px;
					border-right: 1px solid darken($container-border, 2%);
					@include breakpoint($mobile) { float: none; border: 0; }

					img {
						display: block;
					}
				}

				.comment-body {
					margin-left: 115px;
					@include breakpoint($mobile) { margin-left: 0; }

					.meta {
						position: relative;

						.author {
							display: block;
							font-size: 18px;
							color: $container-heading;
						}
						.time {
							font-size: 15px;
							color: $container-text;
						}
						.comment-reply-link {
							position: absolute;
							top: 0; right: 0;
							font-size: 15px;
							color: $load-more-text;
							background: $load-more-bg url("../../images/button-glass.png") repeat-x;
							transition: background 300ms ease-in-out 0s;
							padding: 0 7px;
							border-radius: 2px;

							&:hover {
								background-color: $load-more-bg-hover;
							}
						}	
						.review-rating {
							position: absolute;
							top: 0; right: 0;
							font-size: 15px;
							padding: 0 7px;
							border-radius: 2px;
							text-transform: capitalize;
							background:  url("../../images/button-glass.png") repeat-x;

							&.superb {
								color: $alt-accent-text;
								background-color: $alt-accent-bg;
							}
							&.worst {
								color: #fff;
								background-color: #D31112;
							}
						}


					}
					.content {
						margin-top: 10px;

						p {
							color: $container-text;
						}
					}
				}

			}
		}
	}

	/** Comments Form */
	#comments-form {
		margin-top: 80px;

		h3 {
			font-size: 22px;
			color: $container-heading;
			margin-bottom: 0.5rem;
		}

		> p {
			color: $container-text;
		}

		form {
			margin-top: 40px;

			p { margin-bottom: 14px; }

			input[type="text"],
			input[type="email"],
			input[type="url"],
			input[type="password"],
			input[type="search"],
			select
			{
				background: $container-bg;
				color: $container-text;
				width: 100%;
				padding: 1.3rem 2rem;
				border: solid 6px rgba(0, 0, 0, 0.07);
				box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
				transition: box-shadow 0.3s, border 0.3s;				
			}
			textarea {
				background: $container-bg;
				color: $container-text;
				width: 100%;
				border: solid 6px rgba(0, 0, 0, 0.07);
				box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
				transition: box-shadow 0.3s, border 0.3s;				
			}
			select {
				background: $container-bg url(../../images/select-field-arrow.png) no-repeat 97.5% center;
				-webkit-appearance: none;
				-moz-appearance: none;
				text-indent: 1px;
				text-overflow: '';

			}
			input[type="text"]:focus,
			input[type="email"]:focus,
			input[type="url"]:focus,
			input[type="password"]:focus,
			input[type="search"]:focus,
			textarea:focus,
			select:focus
			{
				border: solid 6px rgba(0, 0, 0, 0.1);
			}

			label {
				color: $body-text;
				display: inline-block;
			}

			::-webkit-input-placeholder { /* Safari, Chrome and Opera */
				color: #7b7b7b;
			}

			:-moz-placeholder { /* Firefox 18- */
				color: #7b7b7b;
			}

			::-moz-placeholder { /* Firefox 19+ */
				color: #7b7b7b;
			}

			:-ms-input-placeholder { /* IE 10+ */
				color: #7b7b7b;
			}

			::-ms-input-placeholder { /* Edge */
				color: #7b7b7b;
			}

			:placeholder-shown { /* Standard one last! */
				color: #7b7b7b;
			}

		}

	}