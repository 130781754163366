
// Typography
$primary-font-stack:    'Lato', sans-serif;
$secondary-font-stack:  'Lato', sans-serif;

// Resposive Breakpoint Values.
$mobile: 0 640px;
$tablet: 641px 1024px;

$touch: 0 1024px;
$no-touch: 1025px;

$lg-desktop:  1025px 1440px;
$xlg-desktop:  1441px 1919px;

$hd-desktop: 1920px 2559px;
$xhd-desktop: 2560px;

$retina:  1440px;
$laptop:  1366px;
