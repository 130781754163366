/*========================================================
  5.  STORE 
========================================================== */

	/** Store Grid */
	#store-grid {
		@include clearfix;

		.entry {

			.wrapper {
				position: relative;
				background: $container-bg;
				margin-bottom: 32px;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

				&:before, &:after {
				    position: absolute;
				    left: 0px;
				    top: 0px;
				    font-size: 16px;
				    line-height: 30px;
				    padding: 0px 22px;
				    z-index: 1;
				    z-index: 999;
				    text-transform: capitalize;
				    background: url("../../images/button-glass.png") repeat-x;
				}
				&:before {
				    content: attr(data-sale);
				    background-color: #D31112;
				    color: #FFF;
				}
				&:after {
				    content: attr(data-stock);
				    background-color: #FBF285;
					color: $container-heading;
				}

				&:hover {
					.add-to-cart {
						opacity: 1;
					}
				}

				.add-to-cart {
					opacity: 0;
					transition: opacity 300ms ease-in-out 0s;

					a {
					    position: absolute;
					    right: 12px;
					    top: 12px;
					    width: 30px;
					    height: 30px;
					    line-height: 29px;
					    text-align: center;
					    border-radius: 100%;
					    font-size: 15px;
					    z-index: 999;
					    text-transform: capitalize;
					    border: 1px solid rgba(180, 180, 180, 0.5);
					    background: #fff url(../../images/button-glass.png) repeat-x 0 0;
					    color: #b4b4b4;

					    &:hover {
					    	border: 1px solid darken($alt-accent-bg, 5%);
						    background-color: $alt-accent-bg;
						    color: $alt-accent-text;
					    }

					    i { line-height: 30px; }

					}
				}				

				.entry-media {
					position: relative;
					display: block;
					margin: 0;

					img {
						display: block;
						width: 100%;
					}

					ul.slick-dots {
						position: absolute;
						bottom: 10px;
						margin: 0;

						li {
							margin: 0;
							width: 15px;
							height: 15px;


							button {
								box-shadow: none;

								&:before {
									content: '';
									color: #fff;
									width: 8px;
									height: 8px;
									border: 1px solid #fff;
									border-radius: 50%;
									opacity: 1;
								}
							}
						}
						li.slick-active {
							button {
								&:before {
									background: #fff;
								}
							}

						}
					}

				}

				.entry-desc {
					padding: 22px 25px;
					position: relative;

					h3 {
						font-size: 16px;
						color: $container-heading;
						margin: 0;
						line-height: 1.3;
					}
					span.taxonomy {
						font-size: 15px;
						color: #96999d;
						text-transform: capitalize;
					}	
					span.price {
						position: absolute;
						top: 25px;
						right: 25px;
						background: #3b3c3e;
						font-size: 15px;
						color: #fff;
						padding: 0 5px;
					}									
				}
			}
		}
	}


	/** Store Cart */
	.store.cart {
		#main-content {
			.cart-summary {
				background: #3b3c3e;
				padding: 20px 35px;

				h3 {
					color: #fff;
					font-size: 24px;
					font-weight: bold;
				}

				form {
					input[type="text"],
					input[type="number"]
					{
						margin-bottom: 0;
						background: #eee;
						border: 0;
					}					
					input[type="submit"] {
						margin: 15px 0 0 0;
					}
				}

				table {
					margin-top: 30px;
					border: 0;
					border-collapse: collapse;

					thead {
						background: transparent;

						tr {
							border-top: 1px solid #565757;
							border-bottom: 1px solid #565757;

							th {
								color: #fff;
								vertical-align: middle;
								border: 0;
								padding: 12px 8px;
								font-weight: normal;

								&.product-name {
									text-align: left;
								}

								&.product-price,
								&.product-quantity,
								&.product-subtotal
								{
									width: 17%;
									text-align: center;
								}

							}
						}
					}

					tbody {
						color: #999999;
						font-weight: normal;

						tr {
							border-bottom: 1px solid #565757;
							background: transparent;

							&:last-child {
								border-bottom: 0;
							}

							td {
								vertical-align: middle;
								padding: 20px 8px;
								border: 0;

								&.product-price,
								&.product-quantity,
								&.product-subtotal
								{
									width: 17%;
									text-align: center;
								}

								&.product-name a {
									color: #999999;
									display: inline-block;

									&:hover {
										color: #fff;
									}
								}
								&.product-remove a {
									color: #999999;
									font-size: 26px;
									font-weight: 300;

									&:hover {
										color: #fff;
									}
								}

								&.actions {
									text-align: right;
									padding-right: 0;
									padding-left: 0;
								}

								.quantity input {
									width: 45px;
									height: 40px;
									text-align: center;
									-moz-appearance: textfield;
									border-radius: 3px;
								}	
								.coupon {
									display: inline-block;
									float: left;
									@include breakpoint($mobile) { float: none; }

									label {
										display: none;
									}
									input[type="text"] {
										display: inline-block;
										width: auto;
										margin-right: 10px;
										min-width: 220px;
										@include breakpoint($mobile) { min-width: 175px; width: 175px; }
										@include breakpoint($lg-desktop) { min-width: 175px; width: 175px; }
									}					

								}		
										
							}					
						}

					}	
				}

				@include breakpoint($mobile) { 

					table {

						display: block; 

						/* Force table to not be like tables anymore */
						thead, tbody, th, td, tr { 
							display: block; 
						}

						/* Hide table headers (but not display: none;, for accessibility) */
						thead tr { 
							position: absolute;
							top: -9999px;
							left: -9999px;
						}

						tr { border: 1px solid #565757; }

						td { 
							/* Behave  like a "row" */
							border: none;
							border-bottom: 1px solid #eee; 
							position: relative;
							padding-left: 50%; 
						}
						
						td:before { 
							white-space: nowrap;
							color: #fff;
						}

						tr.cart_item {
							/* Label the data */
							td:nth-of-type(1):before { content: "Product"; }
							td:nth-of-type(2):before { content: "Price"; }
							td:nth-of-type(3):before { content: "Quantity"; }
							td:nth-of-type(4):before { content: "Total"; }
						}


						input[type="text"] {
							width: auto;
						}

					}

				}

			}
		}
	}

	/** Order Total */
	.store.cart {
		#main-content {
			.order-total {
				background: #fff;
				padding: 20px 35px;
				margin-top: 40px;

				h3 {
					color: #3a3c3d;
					font-size: 24px;
					font-weight: bold;
				}

				table {
					margin-top: 30px;
					margin-bottom: 10px;
					border: 0;

					tbody {
						color: #AFAFAF;
						font-weight: normal;

						tr {
							background: transparent;

							&:first-child {
								border-top: 1px solid #e3e3e3;
							}
						}

						tr th {
							color: #2B2E33;
							font-weight: normal;
							text-align: left;
							vertical-align: middle;
							border: 0;
							border-bottom: 1px solid #e3e3e3;
						}
						tr td {
							text-align: right;
							border: 0;
							border-bottom: 1px solid #e3e3e3;
							padding: 14px 8px;
						}

					}
				}


				.calculate-shipping {
				    display: inline-block;
				    margin-bottom: auto;
				    white-space: nowrap;
				    font-size: 10px;
				    font-weight: 600;
				    color: #3A3C3D;
				    background: #FEEA36;
				    margin-right: 10px;
				    border-radius: 3px;
				    padding: 1px 5px;
				    text-transform: uppercase;
				}
				.proceed-checkout {
					text-align: right;
					margin: 20px 0;
					a {
						margin-right: 0;
					}
				}

			}
		}
	}

	/** Calculate Shipping */
	.store.cart {
		#page-sidebar {
			.shipping-info {
				position: relative;
				background: #e4e4e4;
				color: #3a3c3d;
				padding: 30px 35px;

				&:after {
					position: absolute;
					content: '';
					top: 30px;
					right: 37px;
					width: 30px;
					height: 30px;
					background: url(../../images/icons/delivery-truck5.svg);
					color: #000;
					font-size: 20px;
					opacity: 0.2;
				}

				h4 {
					margin-top: 0;
				}

				form {
					@include clearfix;
					margin-top: 30px;
					margin-bottom: 10px;

					input[type="text"], select {
						background: #f6f6f6;
						border: 0;
						border-bottom: 1px solid #d9d9d9;
						box-shadow: none;
					}
					select {
						background: #f6f6f6 url(../../images/select-field-arrow.png) no-repeat 95% center;

						-webkit-appearance: none;
						-moz-appearance: none;
						text-indent: 1px;
						text-overflow: '';
					}

					button {
						margin-right: 0;
						text-shadow: none;
						margin-top: 20px;

						&:hover { background: #919191 url(../../images/button-glass.png) repeat-x 0 0; }
						&:active { box-shadow: #787878 0 1px 0; }

					}
				}

			}
		}
	}


	/** Single Product */
	.single-product {
		#main-content {
			.entry {
				background: $container-bg;
				margin-bottom: 32px;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

				.entry-header {
					position: relative;
					background: #3b3c3e;
					padding: 45px 20px 40px 20px;

					.title {

						h1 {
							color: #fff;
							font-weight: bold;
							margin: 0;
							font-size: 24px;
						}
						p {
							color: #999999;
							font-size: 18px;
							del {
								margin-right: 5px;
							}
							ins {
								text-decoration: none;
							}
						}

						.review-rating {
							display: inline-block;
							font-size: 15px;
							padding: 0 7px;
							margin-bottom: 5px;
							border-radius: 2px;
							text-transform: capitalize;
							background:  url("../../images/button-glass.png") repeat-x;

							&.superb {
								color: $alt-accent-text;
								background-color: $alt-accent-bg;
							}
							&.worst {
								color: #fff;
								background-color: #D31112;
							}
						}


					}
					.pricing {
						margin-top: 23px;
						text-align: right;
						@include breakpoint($mobile) { text-align: left; }

						.cart {
							display: inline-block;

							.quantity {
								display: inline-block;

								input[type="number"] {
									position: relative;
									top: 1px;
									width: 50px;
									height: 44px;
									text-align: center;
									border-radius: 3px;
									background: #eee;
									border: 0;
									box-shadow: none;
									-moz-appearance: textfield;
								}
							}
							button {
								margin: 0 0 0 10px;
								@include breakpoint($mobile) { margin: 20px 0 0 0; }
							}
						}

					}
				}

				.entry-desc {
					padding: 28px 35px;

					h3 {
						font-size: 24px;
						margin-bottom: 10px;
						margin-top: 5px;
						font-weight: bold;

						a {
							color: $container-heading;
							transition: background 300ms ease-in-out 0s;
							padding: 3px;

							&:hover {
								color: $accent-text;
								background: #F6F6F6;
							}						
						}

					}
					p {
						color: #96999d;
						&:last-of-type {
							margin-bottom: 30px;
						}
					}

					.colors-attrs {
						a {
							display: inline-block;
							margin-right: 5px;
							width: 15px;
							height: 15px;
							border-radius: 100%;

							&.green { background: #94c87c; }
							&.red   { background: #d93333; }
							&.blue  { background: #8a90ff; }
						}
					}
					.size-attrs {
						a {
							display: inline-block;
							font-size: 15px;
							line-height: 24px;
							margin-right: 5px;
							padding: 0 4px;
							&:first-of-type {
								padding-left: 0;
							}

							&.active {
								background-color: #FEEA36;
								color: #3A3C3D;
							}

						}
					}

					table {
					    border: 0;
					    border-top: 1px solid #f2f2f2;

					    &:last-of-type {
					    	border-bottom: 1px solid #f2f2f2;
					    }

					    tbody {

					    	tr:first-child {
					    		th, td {
					            	padding-top: 20px;
					    		}
					    	}

					        tr {
					            background: transparent;

					            th[scope="rowgroup"] {
					            	width: 30%;
					            }
					            th[scope="row"] {
					            	width: 25%;
					            	font-weight: normal;
					            }

					            th {
					            	color: #3B3C3E;
					            	vertical-align: top;
					            	padding: 2px 10px;
					            }

					            td {
					                vertical-align: middle;
					                border: 0;
					            	padding: 2px 10px;
					            	color: #999;
					            }                   
					        }

					    	tr:last-child {
					    		th, td {
					            	padding-bottom: 30px;
					    		}
					    	}

					    }   
					}



				}

				.entry-meta {
					margin: 0;
					list-style: none;
					padding: 0 36px 30px 36px;
					@include clearfix;

					li {
						display: inline-block;
						font-size: 15px;
						color: #96999d;
						float: right;

						&:first-child {
							float: left;
						}

						a {
							transition: color 300ms ease-in-out 0s;

							i {
								transition: color 300ms ease-in-out 0s;
								color: $container-icon;
							}

							&:hover {
								color: $container-icon-hover;
								i {
									color: $container-icon-hover;
								}
							}
						}					

					}
					li.date i {
						margin-right: 8px;
						font-size: 17px;
					}				
					li.like {
						margin-left: 15px;

						i {
							font-size: 20px;
							margin-right: 5px;
						}
						a:hover {
							color: #7bbb5e;
							i {
								color: #7bbb5e;
							}
						}


					}
					li.comments {
						i {
							font-size: 18px;
							margin-right: 5px;
						}
					}				
				}


			}
		}

		#page-sidebar {
			.entry-media {
				position: relative;
				margin: 0;

				&:before, &:after {
				    position: absolute;
				    left: 0px;
				    top: 0px;
				    font-size: 16px;
				    line-height: 32px;
				    padding: 0px 25px;
				    z-index: 1;
				    z-index: 999;
				    text-transform: capitalize;
				    background: url("../../images/button-glass.png") repeat-x;
				}
				&:before {
				    content: attr(data-sale);
				    background-color: #D31112;
				    color: #FFF;
				}
				&:after {
				    content: attr(data-stock);
				    background-color: #FBF285;
					color: $container-heading;
				}

				img {
					width: 100%;
					height: auto;
				}
				ul.slick-dots {
					position: absolute;
					bottom: 10px;
					margin: 0;

					li {
						margin: 0;
						width: 15px;
						height: 15px;


						button {
							box-shadow: none;

							&:before {
								content: '';
								color: #fff;
								width: 8px;
								height: 8px;
								border: 1px solid #fff;
								border-radius: 50%;
								opacity: 1;
							}
						}
					}
					li.slick-active {
						button {
							&:before {
								background: #fff;
							}
						}

					}
				}

			}

			.more-images {
				background: #efefef;

				&:before {
					display: block;
					content: attr(data-more-images);
					background: #fff;
					width: 100%;
					text-align: center;
					padding: 17px;
				}

				a {
					display: inline-block;
					max-width: 123px;
					img {
						width: 100%;
					}
					&.slick-current {
						background: transparent repeating-linear-gradient(45deg, #43F3B7, #43F3B7 1px, #4AE5B0 1px, #4AE5B0 5px) repeat scroll 0% 0%;
						img {
							opacity: 0.3;
						}
					}
				}

			}
		}
	}