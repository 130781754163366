/*========================================================
 8.  FORMS 
========================================================== */

/*---------------------------------------------------------
 General (_forms.scss)
-------------------------------------------------------- */

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="password"],
	input[type="search"],
	select,
	textarea {
		display: block;
		width: 100%;
		color: $field-text;
		margin: 0;
		vertical-align: baseline;
		margin-bottom: 1.4rem;
		padding: 1.2rem 2rem;
		border-radius: 3px;
		background: $field-bg;
		border: 0;	

		border-bottom: 1px solid $field-border;
		border-right: 1px solid $field-border;
		box-shadow: inset 0 1px 2px rgba(0,0,0,0.07);
	}

	input[type="text"]:focus,
	input[type="email"]:focus,
	input[type="url"]:focus,
	input[type="password"]:focus,
	input[type="search"]:focus,
	select:focus,
	textarea:focus {
		outline: medium none;
	}


/*---------------------------------------------------------
 Buttons (_forms.scss)
-------------------------------------------------------- */

	input[type="submit"],
	button, .button,
	input[type="button"] {
		font-family: $primary-font-stack;
		font-size: 15px;
		display: inline-block;
		margin-top: 10px;
		padding: 9px 23px;
		border-radius: 3px;
		border: none;
		border-bottom: solid 1px #F5E383;
		box-shadow: #BE9144 0 2px 0;
		background: #FFD46E;
		background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffd46e), color-stop(1, #ffe88a));
		background-image: -moz-linear-gradient(center bottom, #ffd46e 0%, #ffe88a 100%);
		color: #3c3c3c;
		text-shadow: #FFF0AC 0 1px 0;
		transition: background 300ms ease-in-out 0s;
	}
	input[type="submit"]:hover,
	button:hover, .button:hover,
	input[type="button"]:hover {
		border-bottom: solid 1px #FFF9B6;
		box-shadow: #DCB759 0 2px 0;
		background: #FFFDA5;
		background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fae67f), color-stop(1, #fdffba));
		background-image: -moz-linear-gradient(center bottom, #fae67f 0%, #fdffba 100%);
		color: #545454;
	}
	input[type="submit"]:active,
	button:active, .button:active,
	input[type="button"]:active {
		position: relative;
		top: 1px;
		box-shadow: #DCB759 0 1px 0;
	}



/*---------------------------------------------------------
 Labels (_forms.scss)
-------------------------------------------------------- */

	label {
		font-size: 1.6rem;
		line-height: 46px;
		color: #888;
		display: inline-block;

		span.required {
			color: #F26C4F;
		}
	}


/*---------------------------------------------------------
 Placeholders (_forms.scss)
-------------------------------------------------------- */

	:placeholder-shown { /* Standard one last! */
		color: $field-placeholder;
		font-style: italic;
	}


/*---------------------------------------------------------
 Number Spinner (_forms.scss)
-------------------------------------------------------- */

	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
	input[type=number] {
		-moz-appearance: textfield;
	}


/*---------------------------------------------------------
 Validation (_forms.scss)
-------------------------------------------------------- */

	input.error, textarea.error {
		border: 1px solid #FF0013 !important;
		background: rgba(255, 0, 0, 0.08);
	}
	label.error {
		display: none !important;
	}
	form [required] {}

	#contact-form {
		margin-bottom: 7px;
	}