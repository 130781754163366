
/*===================================================================*/
/* BUTTON STYLES 
/*===================================================================*/

.trend-button {
	font-family: $primary-font-stack;
	font-size: 15px;
	display: inline-block;
	margin-top: 15px;
	margin-right: 15px;
	padding: 9px 23px;
	border-radius: 3px;
	border: none;
	border-bottom: solid 1px #F5E383;
	box-shadow: #BE9144 0 2px 0;
	background: url(../../images/button-glass.png) repeat-x 0 0;
	color: #fff;
	font-weight: normal;
	transition: background 300ms ease-in-out 0s;
}

.trend-button:hover {
	border-bottom: solid 1px #FFF9B6;
	box-shadow: #DCB759 0 2px 0;
	color: #fff;
}
.trend-button:active {
	position: relative;
	top: 1px;
	box-shadow: #DCB759 0 1px 0;
}


/* BUTTON COLORS */
 .trend-button.black { background-color: #333; border-color: #000; box-shadow: #141414 0 2px 0; }
.trend-button.black:hover { background-color: #3C3C3C; }	 
.trend-button.black:active { box-shadow: #141414 0 1px 0; }	 

.trend-button.grey { background-color: #888; border-color: #4e4e4e; box-shadow: #787878 0 2px 0; }
.trend-button.grey:hover { background-color: #919191; }
.trend-button.grey:active { box-shadow: #787878 0 1px 0; }

.trend-button.green { background-color: #7fc33c; border-color: #538421; box-shadow: #69963b 0 2px 0; }
.trend-button.green:hover { background-color: #8dd242; }
.trend-button.green:active { box-shadow: #69963b 0 1px 0; }

.trend-button.blue { background-color: #2caada; border-color: #0b7095; box-shadow: #2681a2 0 2px 0; }
.trend-button.blue:hover { background-color: #32bcef; }
.trend-button.blue:active { box-shadow: #2681a2 0 1px 0; }

.trend-button.yellow { background-color: #FCC71F; border-color: #c79a0b; box-shadow: #c29300 0 2px 0;  }
.trend-button.yellow:hover { background-color: #FFCF38;  } 
.trend-button.yellow:active { box-shadow: #c29300 0 1px 0;  } 

.trend-button.red { background-color: #e3514a; border-color: #ab4b44; box-shadow: #c02a05 0 2px 0; }
.trend-button.red:hover { background-color: #ff5e56; }
.trend-button.red:active { box-shadow: #c02a05 0 1px 0; }

.trend-button.purple { background-color: #d17ac6; border-color: #996192; box-shadow: #996192 0 2px 0; }
.trend-button.purple:hover { background-color: #eb90e0; }
.trend-button.purple:active { box-shadow: #996192 0 1px 0; }

.trend-button.orange { background-color: #FF7654; border-color: #bd2e0b; box-shadow: #e44c26 0 2px 0; }
.trend-button.orange:hover { background-color: #FF8262; }
.trend-button.orange:active { box-shadow: #e44c26 0 1px 0; }

.trend-button.pink { background-color: #FFADCB; border-color: #FDA5C5; box-shadow: #FDA5C5 0 2px 0; }
.trend-button.pink:hover { background-color: #FFB5D0; }
.trend-button.pink:active { box-shadow: #FDA5C5 0 1px 0; }



/*===================================================================*/
/* TOGGLES
/*===================================================================*/

.trend-toggle {
	margin-top: 10px;
}
.trend-toggle:first-child {
	margin-top: 35px;
}

.trend-toggle-heading {
	background: $toggle-bg;
}
.trend-toggle-heading {
	cursor: pointer;
	outline: none;
}

.trend-toggle-heading p {
	margin: 0;
}


.trend-toggle-title > a {
	display: block;
	text-decoration: none;
	padding: 14px 20px; 
}
.trend-toggle-title > a:hover {
	color: $toggle-text;
}
.trend-toggle-title > a:focus {
}
.active .trend-toggle-title > a {
}


.trend-toggle-content {
	padding: 15px 20px;
	border: 1px solid $container-border;
	border-top: 0;
	color: $container-text;
}

.collapse {
	display: none;
}

.collapse.open {
	display: block;
}

.trend-toggle-title > a:before  {
	font-family: "FontAwesome";
	font-size: 14px;
	font-weight: 300;
	content: "\f067";
	margin-right: 18px;
}
.active .trend-toggle-title > a:before {
	content: "\f068";
}




/*===================================================================*/
/* TABS
/*===================================================================*/

.trend-tabs {
	margin-top: 30px;
}


.trend-tab-headings {
	margin: 10px 0 0 0;
	width: 100%;
	padding: 0 ;
	z-index: 400 ;
	display: block;
	@include clearfix;
}

.trend-tab-headings > li {
	float: left;
	list-style: none;
	margin-bottom: 10px;
}

.trend-tab-headings > li > a {
	margin-right: 5px;
	padding: 15px 20px;
	border-radius: 2px 2px 0 0;
}
.trend-tab-headings > li.active > a,
.trend-tab-headings > li.active > a:hover,
.trend-tab-headings > li.active > a:focus
{
	background-color: $toggle-bg;
	cursor: default;
	text-decoration: none;
	border-bottom-color: transparent;
}
.trend-tab-headings > li > a:hover {
	color: $toggle-text;
}

.trend-tab-body {
	background: $toggle-bg;
	float: left;
	opacity: 0;
	padding: 15px 20px;
	border-top: 0;
	z-index: 300;
}

.trend-tab-body.open {
	opacity: 1;
}

.trend-tab-contents {
	background-color: $toggle-text;
	float: left;
	margin-bottom: 50px;
	width: 100%;
	color: $container-text;
}

.trend-tab-contents > .trend-tab-body {
	display: none;
}

.trend-tab-contents > .open {
	display: block;
}

@media only screen 
and (max-width : 400px) {

	.trend-tab-headings > li {
		margin-bottom: 0px;
	}
	
	.trend-tab-headings > li > a {
		margin-right: 1px;
		padding: 7px 7px;
	}
	
	.trend-tab-body {
		padding: 7px;
	}
}


	
/*===================================================================*/
/* HIGHLIGHT
/*===================================================================*/

span.highlight {
	background-color: #FFF49B;
	color: #A5651A;
	padding: 2px 5px;
	font-style: italic;
} 
 

/*===================================================================*/
/* ALERTS
/*===================================================================*/

.trend-alert {
	background-color: #888;
	border-radius: 2px;
	color: #FFF;
	margin: 20px 0 0 0;
	padding: 18px 20px;
	position: relative;
	line-height: 25px;  
}

.trend-alert.note 	{ background: #fdf8e4; color: #846f44; }	
.trend-alert.info 	{ background: #d9edf6; color: #2e6d8e; }
.trend-alert.success { background: #def0d8; color: #417044; }	
.trend-alert.error   { background: #f2dedf; color: #ac4641; }	