/*========================================================
  2.  GRID 
========================================================== */


	$susy: (
		global-box-sizing: border-box,
		gutter-position: inside-static,
		container: 1170px,
		columns: 12,
		column-width: 260px,
		gutters: 1/8,
	);

	@include border-box-sizing;


/** == SCAFFOLDING == */
/** ================================================== */

	#site-container {
		@include breakpoint($mobile) { @include container(300px); }
		@include breakpoint($tablet) { @include container(700px); }
		@include breakpoint($lg-desktop) { @include container(); }
		@include breakpoint($retina) { @include container(1300px); }
		@include breakpoint($xlg-desktop) { @include container(1500px); }
		@include breakpoint($hd-desktop) { @include container(1780px); }
		@include breakpoint($xhd-desktop) { @include container(2420px); }

		#global-sidebar {
			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(2 of 2); }
			@include breakpoint($lg-desktop) { @include span(1 of 4); }
			@include breakpoint($xlg-desktop) { @include span(1 of 5); }
			@include breakpoint($hd-desktop) { @include span(1.25 of 8); }
			@include breakpoint($xhd-desktop) { @include span(1 of 8); }

		}
		#primary-content {
			@include breakpoint($touch) { @include span(1 of 1 nest); }
			@include breakpoint($lg-desktop) { @include span(3 of 4 nest); }
			@include breakpoint($xlg-desktop) { @include span(4 of 5 nest); }
			@include breakpoint($hd-desktop) { @include span(6.75 of 8 nest); }
			@include breakpoint($xhd-desktop) { @include span(7 of 8 nest); }
		}		
	}


/** == PORTFOLIO == */
/** ================================================== */

	#portfolio-grid,
	#store-grid
	{
		.entry {
			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(1 of 2); }
			@include breakpoint($lg-desktop) { @include span(1 of 3); }
			@include breakpoint($xlg-desktop) { @include span(1 of 4); }
			@include breakpoint($hd-desktop) { @include span(1 of 5); }
			@include breakpoint($xhd-desktop) { @include span(1 of 7); }
		}
	}


/** == PRIMARY CONTENT == */
/** ================================================== */

	#primary-content {
		@include clearfix;

		#main-content {

			&.full-width {
				@include breakpoint($mobile) { @include span(1 of 1); }
				@include breakpoint($tablet) { @include span(2 of 2); }
				@include breakpoint($lg-desktop) { @include span(3 of 3); }
				@include breakpoint($xlg-desktop) { @include span(3 of 3); }
				@include breakpoint($hd-desktop) { @include span(2.4 of 3); }
				@include breakpoint($xhd-desktop) { @include span(2.15 of 3); }
			}

			&.flexible {
				@include breakpoint($lg-desktop) { @include span(3 of 3); }
			}

			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(2 of 2); }
			@include breakpoint($lg-desktop) { @include span(2 of 3); }
			@include breakpoint($xlg-desktop) { @include span(2 of 3); }
			@include breakpoint($hd-desktop) { @include span(1.6 of 3); }
			@include breakpoint($xhd-desktop) { @include span(1.45 of 3); }
		}
		#page-sidebar {

			&.flexible {
				@include breakpoint($lg-desktop) { @include span(3 of 3); }
			}

			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(2 of 2); }
			@include breakpoint($lg-desktop) { @include span(1 of 3); }
			@include breakpoint($xlg-desktop) { @include span(1 of 3); }
			@include breakpoint($hd-desktop) { @include span(0.8 of 3); }
			@include breakpoint($xhd-desktop) { @include span(0.7 of 3); }
		}

	}



/** == MISC == */
/** ================================================== */

	.slicknav_nav {
		@include breakpoint($mobile) { @include container(280px); }
		@include breakpoint($tablet) { @include container(700px); }
	}

	.portfolio #load-more,
	.store #load-more
	{
		@include breakpoint($mobile) { @include span(1 of 1); }
		@include breakpoint($tablet) { @include span(2 of 2); }
		@include breakpoint($lg-desktop) { @include span(3 of 3); }
		@include breakpoint($xlg-desktop) { @include span(4 of 4); }
		@include breakpoint($hd-desktop) { @include span(5 of 5); }
		@include breakpoint($xhd-desktop) { @include span(7 of 7); }
	}

	.entry-header {
		@include clearfix;

		.title {
			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(1 of 2); }
			@include breakpoint($lg-desktop) { @include span(1 of 2); }
			@include breakpoint($xlg-desktop) { @include span(2 of 3); }
			@include breakpoint($hd-desktop)  { @include span(2 of 3); }
			@include breakpoint($xhd-desktop) { @include span(2 of 3); }
		}
		.pricing {
			@include breakpoint($mobile) { @include span(1 of 1); }
			@include breakpoint($tablet) { @include span(1 of 2); }
			@include breakpoint($lg-desktop) { @include span(1 of 2); }
			@include breakpoint($xlg-desktop) { @include span(1 of 3); }
			@include breakpoint($hd-desktop)  { @include span(1 of 3); }
			@include breakpoint($xhd-desktop) { @include span(1 of 3); }
		}
	}

