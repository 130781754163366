/*========================================================
  5.  PORTFOLIO 
========================================================== */

	/** Portfolio Grid */
	#portfolio-grid {
		@include clearfix;

		.entry {

			.wrapper {
				background: $container-bg;
				text-align: center;
				margin-bottom: 32px;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

				.entry-media {
					position: relative;
					display: block;

					img {
						display: block;
						width: 100%;
					}
					&:after {
						content: '';
						line-height: 260px;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						color: #fff;
						background: rgba(0, 0, 0, 0.4) url('../../images/hover-bg.png') no-repeat center center;
						z-index: 9999;
						opacity: 0;
						transition: opacity 300ms ease-in-out 0s;
					}
					&:hover:after {
						opacity: 1;
					}

				}
				.entry-desc {
					padding: 20px;
					h3 {
						font-size: 16px;
						color: $container-heading;
						margin: 0;
					}
					span {
						font-size: 15px;
						color: #96999d;
						text-transform: capitalize;
					}				
				}
			}
		}
	}


	/** Main Content */
	#main-content.portfolio-images {
		margin-bottom: 60px;

		.entry-media {
			margin: 0 0 32px 0;

			.image {
				position: relative;
				display: block;

				img {
					display: block;
					width: 100%;
					margin-bottom: 30px;
				}
				figcaption {
					position: absolute;
					right: 20px;
					bottom: 20px;
					background: $alt-accent-bg url("../../images/button-glass.png") repeat-x scroll 0px 0px;
					color: $alt-accent-text;
					padding: 2px 18px;
					border-radius: 3px;
				}
			}				
		}

		.entry-navigation {
			position: relative;
			width: 240px;
			margin: 40px auto 30px auto;
			padding: 40px 0 0 0;
			text-align: center;
			@include clearfix;

			&:before {
				content: '';
				width: 30px;
				height: 1px;
				background: $body-border;
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-50%);
			}

			li {
				display: block;
				float: left;
				width: 70px;
				height: 45px;
				line-height: 45px;
				position: relative;
				margin: 0 5px;
				border-radius: 2px;
				font-size: 20px;
				cursor: pointer;
				background: $tag-bg url(../../images/button-glass.png) repeat-x;
				color: $tag-text;
				transition: all 0.3s ease-in-out;

				&.index {
					font-size: 18px;
				}				
				&:hover {
					background-color: #7bbb5e;
					a {
						color: #fff;
					}
				}

				a {
					display: block;
					text-align: center;
					transition: all 0.3s ease-in-out;
				}
		
			}
		}

		.share-project {
			display: block;
			margin: 0 auto;
			padding: 0;
			text-align: center;

			li {
				display: inline-block;
				position: relative;
				margin: 0 15px;

				&.share-title {
					font-size: 14px;
					text-transform: uppercase;
					color: #999;
					display: block;
					margin-bottom: 10px;
				}

				a {
					font-size: 14px;
					text-transform: uppercase;
					color: #3b3c3e;
					transition: all 0.3s ease-in-out;

					&:hover { color: #000; }
				}

			}
		}


	}

	/** Page Sidebar */
	#page-sidebar.portfolio-desc {
		@include breakpoint($touch) {
			margin-top: 0 !important;
			padding-top: 0 !important;
			border: 0 !important;
		}

		.summary {
			font-size: 15px;
			position: relative;
			margin-bottom: 30px;
			padding: 25px 30px 28px;
			border-radius: 3px;
			background: #3B3C3E;

			&:after {
				position: absolute;
				content: '';
				top: 20px;
				right: 20px;
				width: 20px;
				height: 20px;
				background: url(../../images/icons/info.svg);
				color: #000;
				font-size: 20px;
				opacity: 0.2;
			}
			

			h3 {
			    font-size: 18px;
			    margin-top: 0px;
			    margin-bottom: 10px;
			    color: #FFF;
			}
			p {
				color: #999;
			}

		}

		.meta-info {
			position: relative;
			background: $tag-bg;
			padding: 28px 35px;

			.meta {
				margin-bottom: 25px;

				&:last-of-type {
					margin-bottom: 3px;
				}

				h4 {
					font-size: 16px;
					color: $body-heading;
					margin: 0;
				}
				p {
					color: $body-link;
				}
			}

	
		}

	}