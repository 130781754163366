/*========================================================
  3.  SCAFFOLDING 
========================================================== */

	#site-container {
		padding: 60px 0;

		#global-sidebar {
			> *:not(#site-header) {
				@include breakpoint($touch) { display: none; }
			}
		}		
	}

	/** Site Header */
	#site-header {
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 30px;

		@include breakpoint($touch) { margin-bottom: 40px; }

		#site-logo {
			display: block;
			margin-bottom: 30px;
			img { display: block; }
		}

		&:after {
		  content: '';
		  width: 30px;
		  height: 1px;
		  background: $body-border;
		  position: absolute;
		  left: 0;
		  bottom: 0;
		}		
	}

	/** Social Icons */
	.social-icons {
		margin: 20px 0 0 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;

			a {
				display: inline-block;
				width: 25px;
				height: 25px;
				line-height: 25px;
				font-size: 12px;
				color: #B4B4B4;
				text-align: center;
				background: $container-bg;
				border: 1px solid rgba(0, 0, 0, 0.2);
				border-radius: 50%;
				transition: color 300ms ease-in-out 0s;
			}

			&.facebook {
				a:hover {
					color: #3b5998;
				}
			}
			&.twitter {
				a:hover {
					color: #55acee;
				}
			}
			&.deviantart {
				a:hover {
					color: #4dc47d;
				}
			}	
			&.vimeo {
				a:hover {
					color: #1ab7ea;
				}
			}
		}
	}

	/** Primary Content */
	#primary-content {

		#main-content {
			.wrapper {
				background: $container-bg;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

				.content-header {
					border-bottom: 1px solid $container-border;
					padding: 20px 35px;

					h3 {
						margin: 0;
						color: $container-heading;
						font-size: 18px;
					}
				}
				.featured-module {
					&.media {
						img {
							width: 99.8%;
							margin: 1px;
							display: block;
						}
					}
					&.text {
						position: relative;
						padding: 18px 35px 40px 35px;
						margin-bottom: 20px;

						&:after {
						  content: '';
						  width: 30px;
						  height: 1px;
						  background: lighten($body-border, 5%);
						  position: absolute;
						  left: 40px;
						  bottom: 0;
						}

						h1 {
							text-transform: uppercase;
							font-weight: bold;
							font-size: 36px;
							margin-bottom: 5px;
						}
						p {
							color: #999;
						}
					}					
				}
				.content-body {
					padding: 28px 35px 40px 35px;

					*:first-child {
						margin-top: 0;
					}

					h1, h2, h3, h4, h5, h6 {
						color: $container-heading;
					}
					p {
						color: $container-text;
					}
					img {
						max-width: 100%;
					}
					#awards {
						text-align: center;
					}
				}
			}
		}

		#page-sidebar {
			.inner {
				@include breakpoint($touch) {
					margin-top: 60px;
					padding-top: 60px;
					border-top: 1px solid $body-border;
				}
			}
		}

	}

	/** Mobile Nav */
	.slicknav_menu {
		display:none;

		.slicknav_menu {
			display: inline-block;
		}
	}

	@media screen and (max-width: 1024px) {		
		.slicknav_menu {
			display:block;
		}
	}

	.slicknav_menu {
		padding: 0;

		.slicknav_btn  {
			float: none;
			text-align: right;
			margin: 0;
			padding: 20px 25px;
			background: #3B3C3E;
			border-radius: 0;

			> span {
				float: none;
				display: inline-block;
			}

			.slicknav_icon {
				height: 0.675em;
				margin-left: 0.738em;
			}
		}
	}

	.slicknav_nav {
		padding: 30px 0;
	}


	/** Cart Count */
	#cart-count {
		@include breakpoint($touch) { display: none; }
		position: relative;
		background: $accent-bg;
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 50px;
		line-height: 60px;
		text-align: center;
		display: inline-block;
		border: 2px solid darken($accent-bg, 5%);

		img {
			color: $accent-text;
			width: 27px !important;
		}

		.count {
			font-family: $secondary-font-stack;
			position: absolute;
			right: 2px;
			top: 4px;
			background: #ff0000;
			font-size: 10px;
			color: #fff;
			border-radius: 100%;
			width: 15px;
			height: 15px;
			line-height: 15px;
		}		
	}


	/** Load More */
	#load-more {
		text-align: center;
		margin-top: 20px;

		a {
			display: block;
			color: $load-more-text;
			padding: 10px;
			border-radius: 4px;
			background: $load-more-bg url(../../images/button-glass.png) repeat-x 0 0;
			transition: background 300ms ease-in-out 0s;

			&:hover {
				background-color: $load-more-bg-hover;
			}
		}
	}

	/** Go To Top */
	#go-to-top {
		width: 45px;
		height: 45px;
		line-height: 45px;
		text-align: center;
		border-radius: 100%;
		z-index: 99;
		position: fixed;
		bottom: 60px;
		background: $load-more-bg url("../../images/button-glass.png") repeat-x scroll 0px 0px;
		transition: background 300ms ease-in-out 0s;

		@include breakpoint($touch) { display: none !important; }
		@include breakpoint($lg-desktop) { left: 70px; }
		@include breakpoint($laptop) { left: 115px; }
		@include breakpoint($retina) { left: 150px; }
		@include breakpoint($xlg-desktop) { left: 65px; }
		@include breakpoint($hd-desktop) { left: 80px; }
		@include breakpoint($xhd-desktop) { left: 87px; }


		&:hover {
			background-color: $load-more-bg-hover;
		}

		i {
			font-size: 22px;
			color: $load-more-text;
		}
	}

	/** Preloader */
	#preloader {
	    position: fixed;
	    display: block;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: $body-bg;
	    z-index: 11000;

		&:after {
			content: '';
			position: absolute;
			margin: auto;
			left: 0; right: 0; top: 0; bottom: 0;
			width: 75px;
			height: 45px;
	    	background: url(../../images/site-preloader.gif) no-repeat;
		}
	}

	/** Dragging Slider */
	.slick-slider {
		&.dragging {
			.slick-slide {
				cursor: url(../../images/grabbing.png) 8 8, move;
			}
		}
		.slick-slide {
			cursor: url(../../images/grab.png) 8 8, move;
		}
	}

	/** Notification */
	ul#noty_bottomRight_layout_container {
		li {
			background: #7bbb5d url(../../images/button-glass.png) repeat-x 0 0 !important;
			border: 1px solid #77b45a !important;
			border-radius: 5px !important;
			box-shadow: 0px 25px 10px -15px rgba(0, 0, 0, 0.1) !important;
			margin-top: 10px;
			.noty_message {
				font-size: 16px !important;
				padding: 16px !important;

				.noty_text {
					color: #fff !important;
					&:before {
						font-size: 18px;
						font-family: 'FontAwesome';
						content: "\f058";
						margin-right: 10px;
					}
				}
			}
		}
	}
