// Accent Color
$accent-bg:    			#FEEA36 !default;
$accent-text:    		#3A3C3D !default;

$alt-accent-bg:    		#7BBB5E !default;
$alt-accent-text:    	#FFFFFF !default;

// Body
$body-bg:    			#f2f2f2 url("../../images/line.png") no-repeat fixed 0% 0% !default; 
$body-heading: 			#3a3c3d !default;
$body-text: 			#7B7D80 !default;
$body-link: 			#96999d !default;
$body-border:			#D4D4D4 !default;

// Container
$container-bg:  		#fff !default;
$container-heading:  	#3a3c3d !default;
$container-text: 		#96999d !default;
$container-link: 		#FFF !default;
$container-border:		#f2f2f2 !default;
$container-icon:		#D2D6DB !default;
$container-icon-hover:	#66696D !default;

// Links
$link-color:             #FFF !default;
$link-color-hover:       #000 !default;

// Forms
$field-bg:              rgba(0, 0, 0, 0.07) !default;
$field-text:            #96999D !default;
$field-placeholder:     rgba(0, 0, 0, 0.3) !default;
$field-border:          #fff !default;

// Tags Widget
$tag-bg:             	#E4E4E4 !default;
$tag-text:             	#757575 !default;

// Load More
$load-more-bg:          #a9a9a9 !default;
$load-more-bg-hover:    #919191 !default;
$load-more-text:        #fff !default;

// Shortcodes
$toggle-bg:          	#f1f1f1 !default;
$toggle-text:          	#000 !default;

// Blog
$post-format-bg:        #3b3c3e !default;
$post-format-text:      #fff !default;
$post-format-icon:      #d2d6db !default;
$post-format-border:    rgba(255, 255, 255, 0.1) !default;
